import React, { Component } from "react";
/* We want to import our 'AuthHelperMethods' component in order to send a login request */

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Select } from "@material-ui/core";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import Cart from './cart'
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import { display } from "@mui/system";
class Home extends Component {

    /* In order to utilize our authentication methods within the AuthService class, we want to instantiate a new object */
    constructor(props) {
        super(props);
        this.state = {
            displayMobileMenu: 'unset',
            visibilityMobileMenu: 'hidden',
            countt: 0,
            cartProducts: []
        };
        console.log('kkkkk')
    }

    componentWillReceiveProps(nextProps)
    {
        this.setState(
            {cartProducts: JSON.parse(nextProps.location.state)}
        )
      console.log(nextProps.location.state);
    }
    

    componentWillMount() {
        /* Here is a great place to redirect someone who is already logged in to the protected route */

    }

    render() {
        const { displayMobileMenu, visibilityMobileMenu } = this.state;




        const onMobileMenuClick =()=>{
            let _count = this.state.countt + 1;
            var is_even = function(x) {
                return !(x % 2); 
            }
            if(is_even(_count)){
                this.setState({
                    countt: _count,
                    displayMobileMenu: 'unset',
                    visibilityMobileMenu: 'hidden'
                })
            }else{
                this.setState({
                    countt: _count,
                    displayMobileMenu: 'contents',
                    visibilityMobileMenu: 'visible'
                })
            }
        }

        return (
            <React.Fragment>
                <Backdrop className="backDropLoader" open={this.state.isLoaderOpen} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div className="main-section" style={{ backgroundPosition: '112% 0' }}>
                    <header>
                        <div className="container">
                            <div className="header-content d-flex flex-wrap align-items-center">
                                <div className="logo"><a href="/home" title="">
                                    <img
                                        src="BounceMan.png" alt="" style={{ width: "23%" }} />
                                </a>
                                </div>
                                <ul className="contact-add d-flex flex-wrap">
                                    <li>
                                        <div className="contact-info">

                                        </div>
                                    </li>
                                    <li>
                                        <div className="contact-info">
                                            <img src="/pageImages/icon1.png" alt="" />
                                            <div className="contact-tt">
                                                <h4>Call</h4>
                                                <span>(216) 501-9418</span>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="contact-info">
                                            <img src="/pageImages/icon3.png" alt="" />
                                            <div className="contact-tt">
                                                <h4>Email</h4>
                                                <span>bookings@bouncehousemania.com</span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <span className="mobileCartSec">
                                    <Cart cartList={this.state.cartProducts}  />
                                </span>
                                <div className="menu-btn" onClick={onMobileMenuClick}><a href="#"><span className="bar1"></span> <span className="bar2"></span> <span
                                    className="bar3"></span></a></div>
                            </div>
                            <div className="navigation-bar d-flex flex-wrap align-items-center">
                                <nav>
                                    <ul>
                                        <li><a className="active" href="/home" title="">Home</a></li>
                                        <li><a className="" href="/inventory" title="">Inventory</a></li>
                                        {/* <li><a className="" href="/cart-checkout" title="">Book Now</a></li> */}
                                        <li><a className="" href="/about-us" title="">About Us</a></li>
                                        <li><a className="" href="/contact-us" title="">Contact</a></li>
                                    </ul>
                                </nav>
                                <Cart cartList={this.state.cartProducts} />
                                <ul className="social-links ml-auto">
                                    <li><a href="https://m.facebook.com/bouncemania216/" target="_blank" title=""><FacebookIcon /></a></li>
                                    <li><a href="https://www.instagram.com/?hl=en" target="_blank" title=""><InstagramIcon /></a></li>
                                    <li><a href="https://mobile.twitter.com/bouncemania216" target="_blank" title=""><TwitterIcon /></a></li>
                                </ul>
                            </div>
                        </div>
                    </header>
                    <div className="responsive-menu" style={{display:this.state.displayMobileMenu, visibility: this.state.visibilityMobileMenu}}>
                        <ul>
                            <li><a href="/home" title="/home">Home</a></li>
                            <li><a className="" href="/inventory" title="">Inventory</a></li>
                            {/* <li><a className="" href="/cart-checkout" title="">Book Now</a></li> */}
                            <li><a className="" href="/about-us" title="">About Us</a></li>
                            <li><a className="" href="/contact-us" title="">Contact</a></li>
                        </ul>
                    </div>
                </div>
            </React.Fragment>
        );
    }

}

export default Home;