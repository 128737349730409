import React, { Component } from "react";
/* We want to import our 'AuthHelperMethods' component in order to send a login request */

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import PersonIcon from '@mui/icons-material/Person';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import MapIcon from '@mui/icons-material/Map';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import CarRentalIcon from '@mui/icons-material/CarRental';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import {
    Row, Col, Dropdown, Menu, Divider, Card,
    Form,
    Input,
    Radio,
    Cascader,
    DatePicker,
    InputNumber,
    Select,
    TreeSelect,
    Switch,
    Typography,
    Button
} from "antd";
import { GiConsoleController } from "react-icons/gi";
import { Link, Text } from "@react-pdf/renderer";
const { Meta } = Card;
const { Option } = Select;
const { Title } = Typography;


class checkOutSuccess extends Component {

    /* In order to utilize our authentication methods within the AuthService class, we want to instantiate a new object */
    constructor(props) {
        super(props);
        const windowUrl = window.location.search;
        const useQuery = () => new URLSearchParams(windowUrl);
        const query = useQuery();
        this.state = {
            sessionId: 0,
            isLoaderOpen: true,
            apiUrl: process.env.REACT_APP_API_URL,
            orderData: {},
            productsData: [],
            sessionId: query.get('session_id'),
            getID: query.get('getID')
        };
        
        const sessionId = query.get('session_id');
        const getID = query.get('getID');
        this.setState({ sessionId: sessionId })
        this.fetchCheckoutDet(sessionId, getID);
        this.form = React.createRef();
        localStorage.setItem('itemAddedToCart', JSON.stringify([]));
    }
    


    fetchCheckoutDet = async (session_id, getID) => {
        fetch(`${this.state.apiUrl}/stripe/stripe_success.php`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                session_id, getID
            })
        })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isLoaderOpen: false,
                    productsData: json.data,
                    orderData: json.orderData
                })

                let orderDetails = json.orderData;
                this.form.current.setFieldsValue({
                    first_name: orderDetails.first_name,
                    last_name: orderDetails.last_name,
                    phone: orderDetails.phone,
                    email: orderDetails.email,
                    detepicker: orderDetails.delivery_date,
                    dropOffTime: orderDetails.drop_off_time,
                    address: orderDetails.address,
                    paymentMethod: orderDetails.payment_method
                });
            }).catch(function() {
                alert('Something went Wrong!, Please contact us.')
                //window.location.href = "/home";
                // this.setState({
                //     isLoaderOpen: false })
            });
    }



    render() {
        const { orderData, productsData, sessionId, getID } = this.state;
        const prefixSelector = (
            <Form.Item name="prefix" noStyle>
                <Select style={{ width: 70 }} defaultValue='1'>
                    <Option value="1">+1</Option>
                </Select>
            </Form.Item>
        );

        return (
            <React.Fragment>
                <Backdrop className="backDropLoader" open={this.state.isLoaderOpen} style={{ zIndex: '9999' }} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <section class="pager-section">
                    <div class="container">
                        <div class="pager-content text-center">
                            <h2>Order Details</h2>
                            <ul>
                                <li><a href="#" title="">Home</a></li>
                                <li><a href="#" title="">Products</a></li>
                                <li><span>Order Details</span></li>
                            </ul>
                        </div>
                        <h2 class="page-titlee">BounceHouse</h2>
                    </div>
                </section>
                <section className="classes-section">
                    <div className="container" style={{maxWidth: '1803px'}}>
                        <Row gutter={50}>
                            <Col span={12} style={{ display: 'flex' }}>
                                <Title level={3}>Ordered Products</Title>
                            </Col>
                            <Col span={12}>
                                <Title level={3}>Order Information</Title>
                            </Col>
                        </Row>
                        <Row gutter={50}>
                            <Col  >
                                <Row gutter={20}>
                                {productsData.map((item, idx) => {
                                    return (
                                        <Col >
                                        <Card
                                            hoverable
                                            style={{ width: 350, margin: 'auto 15px' }}
                                            cover={<img alt="example" style={{ width: '230', height: '310' }} src={item.images_uploaded[0].images} />}
                                        >
                                            <Meta title={item.product_name} />
                                            <Meta description={`Delivery Free In - ${item.delivery_free_in}`} />
                                            <Meta description={`Size - ${item.size}`} />
                                            <Meta description={`price - ${item.product_cost}`} />
                                        </Card>
                                        </Col>
                                    )
                                })}
                                </Row>
                            </Col>
                            <Col >
                                <Form
                                    // labelCol={{
                                    //     span: 4,
                                    // }}
                                    // wrapperCol={{
                                    //     span: 14,
                                    // }}
                                    layout="horizontal"
                                    ref={this.form}
                                >

                                    
                                    <Form.Item name='first_name' label="First Name"  >
                                        <Input readOnly/>
                                    </Form.Item>
                                    <Form.Item name='last_name' label="Last Name" >
                                        <Input readOnly/>
                                    </Form.Item>
                                    <Form.Item
                                        name="phone"
                                        label="Phone Number"
                                    >
                                        <Input addonBefore={prefixSelector} style={{ width: '100%' }} readOnly />
                                    </Form.Item>
                                    <Form.Item
                                        name="email"
                                        label="E-mail"
                                    >
                                        <Input readOnly/>
                                    </Form.Item>
                                    <Form.Item name="datepicker" label="Deliver Date" >
                                        <DatePicker style={{pointerEvents: 'none'}}/>
                                    </Form.Item>
                                    <Form.Item name="dropOffTime" label="Drop Off Time" >
                                        <Select style={{pointerEvents: 'none'}}>
                                            <Select.Option value="9AM - 12AM">9AM - 12AM</Select.Option>
                                            <Select.Option value="12PM - 2PM">12PM - 2PM</Select.Option>
                                            <Select.Option value="2PM - 4PM">2PM - 4PM</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item name="address" label="Address">
                                        <Input.TextArea readOnly/>
                                    </Form.Item>
                                    <Form.Item name="paymentMethod" label="Payment Method" >
                                        <Select style={{pointerEvents: 'none'}}>
                                            <Select.Option value="Cash On Delivery">Cash On Delivery</Select.Option>
                                            <Select.Option value="Pay Online">Pay Online</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                    >
                                        <Title level={3} type="success">Payment done successfully! Mandatory : Please Give us a call to confirm the booking</Title>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>
                        {/* <div style={{textAlign:'center'}}>
                        <a href={`/pdfDownload?session_id=${sessionId}&getID=${getID}`}> <Button type="primary">Download Invoice</Button> </a>
                        </div> */}
                    </div>
                </section>
            </React.Fragment >
        );
    }

}

export default checkOutSuccess;