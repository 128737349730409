import React, { Component } from "react";
import "antd/dist/antd.css";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    Row, Col, Card,
    Form,
    Input,
    Button,
    Select,
    DatePicker,
    message,
    Radio

} from "antd";
import moment from "moment";


const { Meta } = Card;
const { Option } = Select;
const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
//class Cart extends Component {
class checkout extends Component {


    constructor(props) {
        super(props);
        this.state = {
            isLoaderOpen: false,
            apiUrl: process.env.REACT_APP_API_URL,
            productList: JSON.parse(localStorage.getItem('itemAddedToCart')),
            componentSize: 'large',
            disabledDatesArrrr: [],
            deliverType: ''

        };
        this.listDisabledDates();
    }

    listDisabledDates = () => {
        let productofListIDs = [];
        this.state.productList.map((item) => {
            productofListIDs.push(item.id);
        })
        fetch(`${this.state.apiUrl}/order/fetchDisabledDates.php`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                productId: JSON.stringify(productofListIDs)
            })
        })
            .then(res => res.json())
            .then(json => {

                this.setState({ disabledDatesArrrr: json.data })
            })
    }





    render() {
        const { productList, apiUrl, isLoaderOpen, componentSize, disabledDatesArrrr } = this.state;

        // const onFormLayoutChange = ({ size }) => {
        //     this.setState({componentSize: size})
        //   };

        const validateMessages = {
            required: '${label} is required!',
            types: {
                email: '${label} is not a valid email!',
                number: '${label} is not a valid number!',
            },
            number: {
                range: '${label} must be between ${min} and ${max}',
            },
        };

        const prefixSelector = (
            <Form.Item name="prefix" noStyle>
                <Select style={{ width: 70 }} defaultValue='1'>
                    <Option value="1">+1</Option>
                </Select>
            </Form.Item>
        );

        const config = {
            rules: [
                {
                    type: 'object',
                    required: true,
                    message: 'Please select time!',
                },
            ],
        };


        const handleResult = async (result) => {
            if (result.error) {
                //responseContainer.innerHTML = '<p>'+result.error.message+'</p>';
            }
        };


        const createCheckoutSession = async (values) => {
               return fetch(`${apiUrl}/stripe/stripe_charge.php`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        checkoutSession: 1,
                        // Name: "NishanthRao",
                        // ID: "4444",
                        // Price: 33,
                        products: JSON.stringify(productList),
                        customerOrderDet: JSON.stringify(values),
                        Currency: 'usd',
                        totalProdCost: JSON.parse(localStorage.getItem('totalProdCost'))
                    })
                }).then(function (result) {
                    return result.json();
                });
        };

        // Specify Stripe publishable key to initialize Stripe.js
          //const stripe = window.Stripe('pk_test_Rn39IEhYmhrj2JkiZQLo6yIu00XMHZaqzU');
        const stripe = window.Stripe("pk_live_rEbzUCr1TUUAxjgrcW9vstkX00eVU6xL3s");

        const onSubmit = (values) => {
            if(values.paymentMethod === 'Pay Online'){
                console.log('rrrrr')
            }else{

                this.setState({isLoaderOpen: true})
                var check = document.getElementsByName('productDate');
                let rrr = [...check];
                let isDateEmpty = [];
                rrr.map((item) => {
                    if (item.value === '') {
                        isDateEmpty.push(true);
                    } else {
                        isDateEmpty.push(false);
                    }
                })
                let validateProductDatee = isDateEmpty.includes(true);
                if (validateProductDatee) {
                    message.warning('Please select date for the individual products');
                } else {
                    if(values.paymentMethod === "Cash On Delivery"){
                        fetch(`${apiUrl}/stripe/cash_on_delivery.php`, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                                products: JSON.stringify(productList),
                                customerOrderDet: JSON.stringify(values),
                                Currency: 'usd',
                                totalProdCost: JSON.parse(localStorage.getItem('totalProdCost'))
                            })
                        }).then(res => res.json())
                        .then(json => {
                            if (json.status === 200) {
                                message.success('Order placed successfully! Mandatory : Please Give us a call to confirm the booking. ');
                                setInterval(function(){window.location.href = "/home"},3000);
                            } else {
                                message.success('Order placed successfully! Mandatory : Please Give us a call to confirm the booking. ');
                                //window.location.href = "/home";
                                setInterval(function(){window.location.href = "/home"},3000);
                            }
            
                        });
                    }else{
                        createCheckoutSession(values).then(function (data) {
                            if (data.sessionId) {
                                stripe.redirectToCheckout({
                                    sessionId: data.sessionId,
                                }).then(
                                    handleResult
                                );
                            } else {
                                handleResult(data);
                            }
                        });
                    }
                }         
            }
        };

        const onChangeProductDate = (e, id) => {
            let _productList = productList;
            let _proctListArr = [];
            _productList.map((item) => {
                if (item.id === id) {
                    item['delivery_date'] = moment(e).format("YYYY-MM-DD");
                    _proctListArr.push(item)
                } else {
                    _proctListArr.push(item)
                }
            })
            this.setState({ productList: _proctListArr })
        }


        const disableStartDate = (current, id) => {
            //console.log(disabledDates)
            //console.log(disabledDatesArrrr[id][0])
            let startDate = moment().startOf('year').format('YYYY-MM-DD');
            let endDate = moment().endOf('year').format('YYYY-MM-DD');
            // const datesToDisable = ["2022-03-22", "2022-03-20", "2022-03-24"];
            const datesToDisable = disabledDatesArrrr[id][0];
            let allDates = []
            while (moment(startDate) <= moment(endDate)) {
                allDates.push(startDate);
                startDate = moment(startDate).add(1, 'days').format("YYYY-MM-DD");
            }
            let disabledDates = allDates.filter(item => !datesToDisable.includes(item))
            //let index = dates.findIndex(date => date  === moment(current).format('YYYY-MM-DD'))
            let index = disabledDates.findIndex(
                (d) =>
                    moment(current).format("YYYY-MM-DD") === moment(d).format("YYYY-MM-DD")
            );
            return index < 0 ? true : false;
        }


        return (
            <React.Fragment>
                <Backdrop className="backDropLoader" open={this.state.isLoaderOpen} style={{ zIndex: '9999' }} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <section class="pager-section">
                    <div class="container">
                        <div class="pager-content text-center">
                            <h2>Checkout</h2>
                            <ul>
                                <li><a href="/home" title="">Home</a></li>
                                <li><a href="/inventory" title="">Products</a></li>
                                <li><span>Checkout</span></li>
                            </ul>
                        </div>
                        <h2 class="page-titlee">BounceHouse</h2>
                    </div>
                </section>
                <section className="classes-section">
                    <div className="container" style={{maxWidth: '1803px'}}>
                        <Row gutter={50}>
                            <Col  >
                                <Row gutter={50}>
                                
                                {productList.map((item, idx) => {
                                    return (
                                        <Col >
                                        <Card
                                            hoverable
                                            style={{ width: 350, margin: 'auto 15px' }}
                                            cover={<img alt="example" style={{ width: '230', height: '310' }} src={item.images_uploaded[0].images} />}
                                        >
                                            <Meta title={item.product_name} />
                                            <Meta description={`Delivery Free In - ${item.delivery_free_in}`} />
                                            <Meta description={`Size - ${item.size}`} />
                                            <Meta description={`price - ${item.product_cost}`} />
                                            <DatePicker
                                                name="productDate"
                                                style={{marginTop:'10px', width:'100%'}}
                                                //disabledDate={disableStartDate(this, item.id)} 
                                                onChange={(event) => onChangeProductDate(event, item.id)}
                                                disabledDate={(event) => disableStartDate(event, item.id)}
                                            />
                                        </Card>
                                        </Col>
                                    )
                                })}
                                </Row>

                            </Col>




                            <Col >
                                <Form
                                    // labelCol={{
                                    //     span: 4,
                                    // }}
                                    // wrapperCol={{
                                    //     span: 14,
                                    // }}
                                    layout="horizontal"
                                    initialValues={{
                                        size: componentSize,
                                        paymentMethod: this.state.deliverType === 'Pick Up' ? 'Pickup' : '',
                                    }}
                                    //onValuesChange={onFormLayoutChange}
                                    validateMessages={validateMessages}
                                    size={componentSize}
                                    onFinish={onSubmit}
                                >
                                    {/* <Form.Item label="Form Size" name="size">
                                        <Radio.Group>
                                            <Radio.Button value="small">Small</Radio.Button>
                                            <Radio.Button value="default">Default</Radio.Button>
                                            <Radio.Button value="large">Large</Radio.Button>
                                        </Radio.Group>
                                    </Form.Item> */}

                                    <Form.Item name='first_name' label="First Name" rules={[{ required: true, message: 'Please input your First Name!' }]}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name='last_name' label="Last Name" rules={[{ required: true, message: 'Please input your Last Name!' }]}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        name="phone"
                                        label="Phone Number"
                                        rules={[{ required: true, message: 'Please input your phone number!' }]}
                                    >
                                        <Input addonBefore={prefixSelector} style={{ width: '100%' }} />
                                    </Form.Item>
                                    <Form.Item
                                        name="email"
                                        label="E-mail"
                                        rules={[
                                            {
                                                type: 'email',
                                                message: 'The input is not valid E-mail!',
                                            },
                                            {
                                                required: true,
                                                message: 'Please input your E-mail!',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    {/* <Form.Item name="datepicker" label="Deliver Date" {...config}>
                                        <DatePicker />
                                    </Form.Item> */}
                                    <Form.Item name="dropOffTime" label="Drop Off Time" rules={[{ required: true, message: 'Please input your Drop off Time!' }]}>
                                        <Select>
                                            <Select.Option value="9AM - 12AM">9AM - 12AM</Select.Option>
                                            <Select.Option value="12PM - 2PM">12PM - 2PM</Select.Option>
                                            <Select.Option value="2PM - 4PM">2PM - 4PM</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item name="address" label="Address" rules={[{ required: true, message: 'Please input your Address!' }]}>
                                        <Input.TextArea />
                                    </Form.Item>
                                    <Form.Item name="deliveryType" label="Delivery Type" rules={[{ required: true, message: 'Please input your Delivery Type!' }]}>
                                        <Radio.Group 
                                             onChange={(e)=>{this.setState({deliverType: e.target.value})}} 
                                            // value={value}
                                        >
                                            <Radio value='Pick Up'>Pick Up</Radio>
                                            <Radio value='Delivery'>Delivery</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    {this.state.deliverType === 'Delivery'?
                                        <Form.Item name="paymentMethod" label="Payment Method" rules={[{ required: true, message: 'Please input your Payment Method!' }]}>
                                            <Select>
                                                <Select.Option value="Cash On Delivery">Cash On Delivery</Select.Option>
                                                {/* <Select.Option value="Pay Online">Pay Online</Select.Option> */}
                                            </Select>
                                        </Form.Item>
                                        : this.state.deliverType === 'Pick Up' ?
                                        <div>
                                            <div style={{color:'red',marginBottom:'10px'}}>* Only Credit Cardit will be accepted for pickup</div>
                                            <Form.Item name="paymentMethod" label="Payment Method" rules={[{ required: true, message: 'Please input your Payment Method!' }]}>
                                                    <Select>
                                                        <Select.Option value="Cash On Delivery">Cash On Delivery</Select.Option>
                                                        <Select.Option value="Pay Online">Pay Online</Select.Option>
                                                    </Select>
                                                </Form.Item>
                                        </div>
                                        :
                                        ''
                                    }
                                    {/* <Form.Item name="paymentMethod" label="Payment Method" rules={[{ required: true, message: 'Please input your Payment Method!' }]}>
                                        <Select>
                                            <Select.Option value="Cash On Delivery">Cash On Delivery</Select.Option>
                                            <Select.Option value="Pay Online">Pay Online</Select.Option>
                                        </Select>
                                    </Form.Item> */}

                                            {/* <Form.Item name="paymentMethod" label="Payment Method" rules={[{ required: true, message: 'Please input your Payment Method!' }]}>
                                                <Select>
                                                    <Select.Option key='Cash On Delivery' value="Cash On Delivery" >Payment On Delivery</Select.Option>
                                                </Select>
                                            </Form.Item> */}
                                    
                                    <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                                        <Button className="checkoutPagePaymentBtn" type="primary" htmlType="submit">
                                            Submit
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>
                        {/* <StripePaymentForm /> */}
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

export default checkout;
