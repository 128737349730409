import React, { Component } from "react";
/* We want to import our 'AuthHelperMethods' component in order to send a login request */

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';



class productDetails extends Component {

    /* In order to utilize our authentication methods within the AuthService class, we want to instantiate a new object */
    constructor(props) {
        super(props);
        this.state = {
            productId: 0,
            productDetails: {},
            images: [],
            isLoaderOpen: true,
            apiUrl: process.env.REACT_APP_API_URL,
            productCartList: JSON.parse(localStorage.getItem('itemAddedToCart'))

        };
        const windowUrl = window.location.search;
        const useQuery = () => new URLSearchParams(windowUrl);
        const query = useQuery();
        const productId = query.get('id');
        this.setState({ productId: productId })
        this.getCarDet(productId);
    }

    getCarDet = async (productId) => {
        const requestType = 'productDetails';
        fetch(`${this.state.apiUrl}/products/listProducts.php`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                productId, requestType
            })
        })
            .then(res => res.json())
            .then(json => {
                if (json.status === 200) {
                    this.setState({
                        productDetails: json.data[0],
                        images: json.data[0].images_uploaded,
                        isLoaderOpen: false
                    });
                } else {
                    this.setState({
                        productDetails: {},
                        images: [],
                        isLoaderOpen: false
                    });
                }

            })
    }

    



    render() {
        const { productDetails, productCartList } = this.state;

        const onCLickChangeValue = async(item) => {
            let _productCartList = productCartList ? productCartList : [];
            item['quantity'] = 1;
            if(_productCartList){
                _productCartList = _productCartList.filter(obj => obj.id !== item.id).concat(item)
            }else{
                _productCartList.push(item)
            }
            this.setState({productCartList: _productCartList})
            localStorage.setItem('itemAddedToCart', JSON.stringify(_productCartList));
            window.location.reload(true);
        };

        return (
            <React.Fragment>
                <Backdrop className="backDropLoader" open={this.state.isLoaderOpen} style={{ zIndex: '9999' }} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <section class="pager-section">
                    <div class="container">
                        <div class="pager-content text-center">
                            <h2>{productDetails.product_name}</h2>
                            <ul>
                                <li><a href="/home" title="">Home</a></li>
                                <li><a href="/inventory" title="">Products</a></li>
                                <li><span>{productDetails.product_name}</span></li>
                            </ul>
                        </div>
                        <h2 class="page-titlee">BounceHouse</h2>
                    </div>
                </section>
                <section className="page-content">
                    <div className="container">
                        <div className="teacher-single-page">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="teacher-coly">
                                        <Carousel autoPlay={true} infiniteLoop={true} swipeable={true}>
                                            {this.state.images.map(({ images, product_id }, index) => (
                                                <div>
                                                    <img src={images} alt="" />
                                                    {/* <ul className="social-icons">
                                                        <li><a href="#" title=""><i className="fab fa-facebook-f"></i></a></li>
                                                        <li><a href="#" title=""><i className="fab fa-twitter"></i></a></li>
                                                        <li><a href="#" title=""><i className="fab fa-linkedin-in"></i></a></li>
                                                    </ul> */}
                                                </div>
                                            ))}
                                        </Carousel>

                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="teacher-content">
                                        <h3 className="productDetName">{productDetails.product_name}</h3>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                <div className="rol-z">
                                                    <img src="/pageImages/ro1.png" alt="" />
                                                    <div className="rol-info">
                                                        <h3>Delivery Free in</h3>
                                                        <span>{productDetails.delivery_free_in}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                <div className="rol-z">
                                                    <img src="/pageImages/ro1.png" alt="" />
                                                    <div className="rol-info">
                                                        <h3>Size</h3>
                                                        <span>{productDetails.size}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                <div className="rol-z">
                                                    <img src="/pageImages/ro1.png" alt="" />
                                                    <div className="rol-info">
                                                        <h3>Cost</h3>
                                                        <span style={{display:'flex'}}>$<span style={{textDecoration:'line-through'}}>{parseInt(productDetails.product_cost) + parseInt('40')}</span>/{productDetails.product_cost}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p>{productDetails.description}</p>
                                        <Button style={{width:'fit-content', margin: '0 0 50px 0'}} className="addToCartbtn" onClick={() => onCLickChangeValue(productDetails)} >Add to Cart</Button>

                                        <ul className="tech-detils">
                                            <li>
                                                <div>
                                                <div><h3>CALL</h3></div>
                                                <div>(216) 501-9418</div>
                                                </div>
                                            </li>
                                            <li>
                                                <h3>EMAIL</h3>
                                                <div>bookings@bouncehousemania.com</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                                                    
                    </div>
                </section>
                <section class="newsletter-section">
                    <div class="container">
                        <div class="newsletter-sec">
                            <div class="row align-items-center">
                                <div class="col-lg-12">
                                    <div class="newsz-ltr-text">
                                        <h2>Crowned the Most Safe and Fun Experience Ever! Bring Fun Life To Your Kids</h2>
                                        <a href="/inventory" title="" class="btn-default">Book Now <i class="fa fa-long-arrow-alt-right"></i></a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }

}

export default productDetails;