import React, { Component } from 'react';

class PaymentForm extends Component {
  constructor(props) {
    super(props);
    this.paymentForm = null;
    this.paymentFormRef = React.createRef();
  }

  componentDidMount() {
    this.loadSquareSdk();
  }

  loadSquareSdk = () => {
    const script = document.createElement('script');
    script.src = 'https://js.squareupsandbox.com/v2/paymentform';
    script.onload = this.initializePaymentForm;
    document.body.appendChild(script);
  };

  initializePaymentForm = () => {
    this.paymentForm = new window.SqPaymentForm({
      applicationId: 'sandbox-sq0idb-_l047o-IiGIQNxMfHy1Fdg',
      inputClass: 'sq-input',
      callbacks: {
        cardNonceResponseReceived: this.handleCardNonceResponseReceived,
        paymentFormLoaded: this.handlePaymentFormLoaded,
      },
    });
    this.paymentForm.build();
  };

  handlePaymentFormLoaded = () => {
    this.paymentFormRef.current.appendChild(this.paymentForm.getWrapperElement());
  };

  handleCardNonceResponseReceived = (errors, nonce) => {
    if (errors) {
      // Handle errors
      console.error(errors);
    } else {
      // Send the nonce to your server for payment processing
      console.log(nonce);
    }
  };

  render() {
    return (
      <div>
        <div ref={this.paymentFormRef} />
        <button onClick={() => this.paymentForm.requestCardNonce()}>Pay</button>
      </div>
    );
  }
}

export default PaymentForm;
